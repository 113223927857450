import React, { useEffect, useState } from "react"
import classNames from "classnames"
import axios from "axios"

import styles from "./utils/layout.module.scss"
import {
  hasSeenNotification,
  setNotificationAsSeen,
} from "./services/notification"

import { isBrowser } from "services/general"
// import {
// GATSBY_ORDER_AS_GUEST,
// GATSBY_AUTH_NOTIFICATION,
// GATSBY_ORDER_AS_GUEST_KEY,
// GATSBY_ORDER_AS_GUEST_VALUE,
// } from "gatsby-env-variables"
import { Link } from "gatsby"
import { getAnnouncements } from "./services/getAnnouncements"

const Notification = () => {
  const [notificationHidden, setNotificationHidden] = useState(false)
  const hasSeenNotificationMessage = hasSeenNotification()
  const [announcement, setAnnouncement] = useState(null)

  useEffect(() => {
    const getBanner = async () => {
      try {
        const params = {
          website: process.env.GATSBY_GITHUB_REPO,
          env: process.env.GATSBY_ENV,
        }

        const response = await axios.get(
          process.env.GATSBY_AIRTABLE_BANNER_ENDPOINT,
          { params }
        )

        let actualBannerContent = response?.data?.content || null

        setAnnouncement(actualBannerContent)
      } catch (error) {}
    }

    getBanner()
  }, [])

  if (announcement && !hasSeenNotificationMessage)
    return (
      <div
        className={classNames(
          "notification is-warning",
          {
            "is-hidden": notificationHidden,
          },
          styles["notification"]
        )}
      >
        <button
          className="delete"
          onClick={() => {
            setNotificationHidden(true)
            setNotificationAsSeen()
          }}
        ></button>
        <div
          dangerouslySetInnerHTML={{
            __html: announcement
              ?.map((elem) => elem?.replace("<p>", "<p class='is-size-6'>"))
              ?.join(""),
          }}
          className="has-text-centered"
        ></div>
      </div>
    )

  return null
}

export default Notification
