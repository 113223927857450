import React, { Fragment } from "react"
import PropTypes from "prop-types"

import SEO from "./SEO"
import Navbar from "./Navbar"
import Notification from "./Notification"
import HelpCenterBanner from "../StaticPages/HelpCenter/HelpCenterBanner"
import Footer from "./Footer"
import FloatingActionButton from "../Elements/FloatingActionButton"

import { faFacebook } from "@fortawesome/free-brands-svg-icons"

/**
 * @param {string} seoTitle string
 * @param {string} title string
 * @param {string} subtitle string
 * @param {array} path array
 * @param {object} display settings to display footer/help banner
 * @param {} children
 */

const Layout = ({
  children,
  title,
  subtitle,
  seoTitle,
  display = { footer: true, helpCenterBanner: true },
  path,
}) => {
  let pathArray = path ? path.split("/") : []

  return (
    <Fragment>
      <SEO title={seoTitle} />
      <Notification />
      <Navbar path={pathArray} />
      {title && (
        <h2 className="has-text-centered mb-0 has-text-primary mx-2-mobile pb-3">
          {title}
          <h4 className="subtitle has-text-grey has-text-centered my-1 is-size-6 mx-2-mobile">
            {subtitle}
          </h4>
        </h2>
      )}
      <main className="mb-3">{children}</main>

      {display.helpCenterBanner && <HelpCenterBanner />}
      {display.footer && <Footer />}
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoTitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  path: PropTypes.array,
}

export default Layout
